<script setup>
import VLink from '@/components/ui/buttons/VLink'
import VTextBlock from '@/components/textblock/VTextBlock'
import VBestOfferList from '@/components/list/BestOfferList/VBestOfferList'
import useVModal from '@/use/Base/useVModal'
import VOrganizerSummarizingModalChangePosition
from '@/components/views/Organizer/Summarizing/Modal/VOrganizerSummarizingModalChangePosition'
import { useSummarizingMounted } from '@/use/Form/Organizer/Summarizing/useSummarizingMounted'
import { computed, provide, reactive } from 'vue'
import VOrganizerSummarizingCart from '@/components/views/Organizer/Summarizing/VOrganizerSummarizingCart'
import VOrganizerSummarizingInfo from '@/components/views/Organizer/Summarizing/VOrganizerSummarizingInfo'
import { useRouter } from 'vue-router'
import OrganizerMyTradeTabsHistoryOffer from '@/views/Organizer/Trade/OrganizerMyTradeTabsHistoryOffer'
import { Alert } from '@/extension/Alert/Alert'
import { TRADE_HAPPEND } from '@/utils/lot/lotStatus'
import { changeStatus, setWinner } from '@/http/offer/offerApi'
import { RenderApiError } from '@/extension/Error/RenderApiError'
import VOrganizerSummarizingMenu from '@/components/views/Organizer/Summarizing/VOrganizerSummarizingMenu'
import VToggleDisplay from '@/components/ui/buttons/VToggleDisplay'
import { useStore } from 'vuex'
import VTableOffer from '@/components/views/Organizer/Summarizing/VTableOffer'
import ModalTable from '@/components/ui/modal/ModalTable'
import useModalTable from '@/use/Modal/useModalTable'
import { POSITION_CRITERION_TABLE_HEAD_MODAL } from '@/utils/consts/tableHead'

// CONSTS
const router = useRouter()
const store = useStore()
const lot = reactive({})
provide('lot', lot)

// LET COMPUTED
const selected = reactive([])
const handlerErrors = new RenderApiError()

const getIsDisplayTable = computed(() => {
  return store.getters['display/getIsDisplayTable']
})

const getApproveList = computed(() => {
  return store.getters['offer/getApproveList']
})

const getOfferWithoutFakeBidder = computed(() => {
  return store.getters['offer/getOffersWithoutFakeBidder']
})

// Connect
const {
  isTradeActive,
  offers, winnersList
} = useSummarizingMounted(lot)

const {
  openVModal: openEditModal,
  closeVModal: editModal,
  showVModal: showEditModal
} = useVModal()

// FN
function goOrganizerRegisterRoute () {
  router.push({ name: 'organizer-register' })
}

const iterableOffers = (sendObject) => {
  offers.forEach(offer => {
    if (offer.selectedOffers?.length) {
      offer.selectedOffers.forEach((selOffer) => {
        const pos = offer.offers.find(el => el.id === selOffer)
        if (pos) {
          const userId = pos.user_id
          if (sendObject[userId]) {
            const candidate = sendObject[userId].find(item => item.id === pos.id)

            if (candidate) return

            sendObject[userId].push({ id: pos.id, position_id: pos.position_id })
          } else {
            sendObject[userId] = [{ id: pos.id, position_id: pos.position_id }]
          }
        }
      })
    }
  })
}

const searchPosition = (sendObject, offer) => {
  offers[offer.idx] = offer
  iterableOffers(sendObject, offer)
}

const send = async () => {
  try {
    const lotId = lot.id

    const sendObject = {}
    if (!selected.length) {
      getApproveList.value.forEach((offer) => {
        searchPosition(sendObject, offer)
      })
    }
    Object.keys(sendObject).forEach(key => {
      selected.push({ id: Number(key), offer_id: sendObject[key] })
    })
    await setWinner(lotId, selected)
    await changeStatus(lotId, TRADE_HAPPEND)
    await Alert.show('success', `По лоту ${lot.name} победители определены`)
    await router.push({ name: 'organizer-register' })
  } catch (e) {
    await handlerErrors.showAlert(e)
  }
}

const closeEditModal = (arr) => {
  winnersList.length = 0
  arr.forEach((i) => {
    if (i === null) return
    winnersList.push(offers[i])
    store.commit('offer/setWinnerList', offers[i])
  })
  Alert.show('success', 'Лучшие ценовые предложениея успешно изменены')
  editModal()
}

const {
  table: tableCriterion,
  showModalTable,
  openModalTable,
  closeModalTable,
  refreshModalTableRows
} = useModalTable({ head: POSITION_CRITERION_TABLE_HEAD_MODAL })

const modalTableData = reactive({
  organization_name: '',
  characteristic: ''
})

function openModalCriterion ({ position, user }) {
  setModalTableData({
    ...user,
    characteristic: position.characteristic
  })
  refreshModalTableRows(position.parameters)
  openModalTable()
}

function closeModalCriterion () {
  closeModalTable()
  setModalTableData({})
}

// eslint-disable-next-line camelcase
function setModalTableData ({ organization_name = '', characteristic = '' }) {
  // eslint-disable-next-line camelcase
  modalTableData.organization_name = organization_name
  modalTableData.characteristic = characteristic
}
</script>

<template>
  <VLink class="block__link-back" @click="goOrganizerRegisterRoute">Назад</VLink>
  <div class="block__subtitle mt-40 mb-20">
    Подведение итогов
  </div>

  <VOrganizerSummarizingCart
    :isTradeActive="isTradeActive"
    :winnersList="getApproveList"
    :offers="getOfferWithoutFakeBidder"
    @send="send"
  />

  <div class="p-30 mb-30 bg--light-gray" v-if="lot.note">
    <VTextBlock
      subtitle="Примечание"
      :description="lot.note"
    />
  </div>

 <VOrganizerSummarizingInfo />
  <div v-if="getOfferWithoutFakeBidder.length">
    <div class="between mb-30">
      <div class="block__subtitle-small max-w-400">
        Лучшие ценовые предложения
      </div>

      <VToggleDisplay />
    </div>

    <VOrganizerSummarizingMenu
      :is-table="getIsDisplayTable"
      @open="openEditModal"
      :is-trade-active="isTradeActive"
    />

    <VBestOfferList
      v-if="!getIsDisplayTable"
      :type="lot.divisibility"
      class="mb-60"
      with-buttons
      :is-trade-active="isTradeActive"
      :best-offer-list="winnersList"
      :offers="getOfferWithoutFakeBidder"
    />

    <VTableOffer
      v-else
      @open-modal="openModalCriterion"
    />
  </div>

  <teleport to="body">
    <VOrganizerSummarizingModalChangePosition
      v-if="getOfferWithoutFakeBidder.length"
      :is-open="showEditModal"
      @close="closeEditModal"
      @closeNon="editModal"
    />
  </teleport>

  <OrganizerMyTradeTabsHistoryOffer v-if="getOfferWithoutFakeBidder.length" />

  <teleport to="body">
    <ModalTable
      v-if="showModalTable"
      title="ЗНАЧЕНИЕ КРИТЕРИЕВ"
      :table-head="tableCriterion.head"
      :table-rows="tableCriterion.rows"
      @close="closeModalCriterion"
    >
      <div class="column gap-20">
        <span>{{ modalTableData.organization_name }}</span>
        <span>{{ modalTableData.characteristic }}</span>
      </div>
    </ModalTable>
  </teleport>
</template>
