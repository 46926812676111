import { getLot, getLotResult } from '@/http/lot/lotApi'
import { Alert } from '@/extension/Alert/Alert'
import { onMounted, reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { setMountedBestWinner } from '@/utils/lot/function.lot'

export function
useSummarizingMounted (lot) {
  const router = useRouter()
  const store = useStore()
  const offersWithoutFakeBidder = reactive([])
  const offers = reactive([])
  const winnersList = reactive([])
  const isTradeActive = ref(true)

  async function cloneLot (lotId) {
    try {
      const { data } = await getLot(lotId)

      const tradeActiveStatus = ['Торги состоялись', 'Торги не состоялись', 'Подведение итогов']

      if (tradeActiveStatus.includes(data.status.name)) {
        isTradeActive.value = true
      }

      const keys = Object.keys(data)
      keys.forEach((key) => {
        lot[key] = data[key]
        lot.date = [
          { title: 'Дата начала и время публикации', description: data.published_at },
          { title: 'Дата и время начала ТП', description: data.started_at },
          { title: 'Дата и время окончания ТП', description: data.duration },
          {
            title: 'Статус',
            description: data.status.name,
            decorator: { name: 'Status' }
          }
        ]
      })

      const arrFiles = []
      if (lot.protocol?.length) {
        arrFiles.push(...lot.protocol)
      }

      if (lot.file?.length) {
        arrFiles.push(...lot.file)
      }

      store.commit('files/setFiles', arrFiles)
    } catch (e) {
      await router.push({ name: 'organizer-register' })
    }
  }
  function redirectToHistoryOffer (id) {
    router.push({ name: 'organizer-summarizing-lot', params: { id } })
  }
  async function getOffers (lotId) {
    const { data, byPosition } = await getLotResult(lotId)
    if (!data.length) {
      store.commit('offer/setOffersWithoutFakeBidder', [])
      store.commit('offer/setActiveWinners', [])
      return
    }

    store.commit('offer/setBeforeResultPosition', byPosition)
    const withoutFakeBidder = data.filter(item => !item.is_fake)

    if (withoutFakeBidder.length) {
      offersWithoutFakeBidder.push(...withoutFakeBidder)
      store.commit('offer/setOffersWithoutFakeBidder', offersWithoutFakeBidder)
      store.commit('offer/setActiveWinners', offersWithoutFakeBidder)
    }

    if (data.length) {
      offers.push(...data)
    }

    if (offers.length) {
      offers.forEach(offer => {
        offer.lot = lot
      })
    }

    setMountedBestWinner(winnersList, offersWithoutFakeBidder)
  }

  async function getResponse () {
    try {
      const lotId = router.currentRoute.value.params.id
      redirectToHistoryOffer(lotId)
      await cloneLot(lotId)
      await getOffers(lotId)
    } catch (e) {
      await Alert.show('error', e)
    }
  }

  onMounted(getResponse)

  return {
    offers,
    isTradeActive,
    winnersList
  }
}
