<script setup>
import { defineEmits, defineProps, inject } from 'vue'
import VLink from '@/components/ui/buttons/VLink'
import VIcon from '@/components/ui/icon/VIcon'
import { BIDDING_CONTROLLER } from '@/utils/consts'
// import VOnlyTopRangCheckbox from '@/components/ui/form/Checkbox/Modification/VOnlyTopRangCheckbox'
import { useRouter } from 'vue-router'

const biddingController = inject(BIDDING_CONTROLLER)
const router = useRouter()
defineEmits(['open'])

defineProps({
  isTradeActive: {
    type: Boolean,
    required: true
  },
  isTable: {
    type: Boolean,
    required: true
  }
})

async function downloadExcel() {
  await biddingController.downloadExcelResultOrganizer(router.currentRoute.value.params.id)
}

</script>

<template>
  <div
    v-if="!isTable"
    class="right mb-30 vertical-center"
  >
<!--    <VOnlyTopRangCheckbox />-->
    <VLink
      v-if="isTradeActive"
      class="ml-20"
      icon="Edit"
      @click="$emit('open')"
    >
      Редактировать
    </VLink>
  </div>

  <div
    v-else
    class="right mb-30 vertical-center"
  >
    <button
      class="btn center vertical-center"
      @click="downloadExcel"
    >
      <VIcon
        class="mr-10"
        width="26"
        height="27"
        iconName="Excel"
      />
      Скачать в Excel
    </button>
  </div>
</template>
