<script setup>
import VStatusInfo from '@/components/statusElements/VStatusInfo'
import { inject, defineProps, defineEmits, ref } from 'vue'
import { Alert } from '@/extension/Alert/Alert'
import { TRADE_DONT_HAPPEND } from '@/utils/lot/lotStatus'
import { useRouter } from 'vue-router'
import { BIDDING_CONTROLLER, LOT_CONTROLLER } from '@/utils/consts'

const emits = defineEmits(['send'])
const props = defineProps({
  winnersList: {
    type: Array,
    required: true
  },
  isTradeActive: {
    type: Boolean,
    required: true
  },
  offers: {
    type: Array,
    required: true
  }
})

const lot = inject('lot')
const biddingController = inject(BIDDING_CONTROLLER)
const lotController = inject(LOT_CONTROLLER)
const isLoading = ref(false)
const router = useRouter()

const doThereIsAStatus = new Map([
  ['HAPPEND', happend],
  ['UNHAPPEND', unHappend],
  ['DELETE', deleteLot],
  ['HIDE', hide]
])

async function deleteLot (lotId) {
  await lotController.delete(lotId)
  await router.push({ name: 'organizer-register' })
}

async function hide (lotId) {
  await lotController.hide(lotId)
}

async function happend () {
  if (lot.divisibility) {
    if (props.winnersList.length === 1) {
      emits('send')
    } else {
      await Alert.show('error', 'Необходимо выбрать одного победителя, лот является неделимым')
    }
  } else {
    emits('send')
  }
}

async function unHappend (lotId) {
  await biddingController.sendResultStatus(lotId, TRADE_DONT_HAPPEND)
}

async function sendStatus (status) {
  try {
    isLoading.value = true
    const lotId = lot.id
    await doThereIsAStatus.get(status)(lotId)
  } finally {
    isLoading.value = false
  }
}
</script>

<template>
  <div class="grid mb-20 p-30 bg--light-gray">
    <div class="block__subtitle">
      {{ lot.id }}
    </div>
    <div class="block__subtitle-small">
      {{ lot.name }}
    </div>
    <VStatusInfo
      :info="lot.date || []"
      type="result"
    />
    <div
      v-if="isTradeActive && offers?.length"
      class="buttons-wrap"
    >
      <button
        class="btn btn-primary btn-primary--padding btn-bg-green"
        @click="sendStatus('HAPPEND')"
        :disabled="isLoading || !props.winnersList.length || (lot.status.id === 10 || lot.status.id === 12)"
      >
        Торги состоялись
      </button>

      <button
        class="btn btn-primary btn-primary--padding btn-bg-transparent"
        @click="sendStatus('UNHAPPEND')"
        :disabled="isLoading || (lot.status.id === 10 || lot.status.id === 12)"
      >
        Торги не состоялись
      </button>
    </div>

    <div
      v-else
      class="buttons-wrap"
    >
      <button
        class="btn btn-primary btn-primary--padding btn-bg-green"
        @click="sendStatus('DELETE')"
        :disabled="isLoading"
      >
        Удалить
      </button>

      <button
        class="btn btn-primary btn-primary--padding btn-bg-transparent"
        @click="sendStatus('HIDE')"
        :disabled="isLoading"
      >
        Отменить
      </button>
    </div>
  </div>
</template>
