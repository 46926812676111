<script setup>
import TradeHistoryOffer from '@/components/views/Trade/TradeHistoryOffer'
import VModal from '@/components/ui/modal/VModal'
import useVModal from '@/use/Base/useVModal'
import VSelect from '@/components/ui/form/VSelect'
import { computed, inject, reactive, ref, watch } from 'vue'
import { useRoute } from 'vue-router'

const lot = inject('lot')

const actionQuery = ref('')
const select = reactive({
  value: null
})
const componentKey = ref(0)

const route = useRoute()
const options = reactive([])
const dynamicUrl = ref(`/trade-offer/lots/${route.params.id}/before-result`)

const getPosition = computed(() => {
  return lot.position
})
watch(getPosition, () => {
  if (getPosition.value?.length) {
    options.push(...getPosition.value)
  }
})

function changeUrl () {
  const ids = select.value.map(pos => {
    return pos.id
  })
  actionQuery.value = `&position_ids=${ids}`
  componentKey.value++
}

const { showVModal, toggleVModal, closeVModal } = useVModal()

function saveFilter () {
  changeUrl()
  closeVModal()
}
</script>

<template>
  <button
    v-if="!lot.divisibility"
    class="btn btn-primary btn-bg-green mb-20"
    @click="toggleVModal"
  >
    Фильтр
  </button>

  <teleport to="body">
      <VModal
        v-if="showVModal"
        modal-inner-class="modal-table bg--white scroll-y"
        @close="closeVModal"
      >
        <h3 class="modal__title mb-60">ФИЛЬТР ПО ПОЗИЦИЯМ</h3>
          <VSelect
            id="filter-pos"
            name="filter-pos"
            :options="options"
            :allow-empty="true"
            v-model="select.value"
            is-custom-option
            track-by="id"
            key-record="id"
            multiple
            placeholder="Выберите позицию"
            class="select-border-bottom"
          />

        <div class="elements__buttons">
          <button
            class="btn btn-primary btn-bg-green"
            @click="saveFilter"
          >Сохранить</button>
          <button
            class="btn btn-primary btn-bg-transparent"
            @click="closeVModal"
          >Отмена</button>
        </div>
      </VModal>
  </teleport>

  <TradeHistoryOffer
    :url="dynamicUrl"
    :action="actionQuery"
    :key="componentKey"
  />
</template>
