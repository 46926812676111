<script setup>
import VTable from '@/components/ui/table/VTable'
import { TABLE_OFFER_INJECT } from '@/utils/consts'
import { computed, inject, onMounted, reactive, watch, defineEmits } from 'vue'
import { useStore } from 'vuex'
import { arrayMove } from '@/utils/utils'

const store = useStore()
const emit = defineEmits(['open-modal'])
const getOffers = computed(() => store.getters['offer/getOffersWithoutFakeBidder'])
const getActivePositions = computed(() => store.getters['offer/getActivePositions'])
const getWinnersList = computed(() => store.getters['offer/getPrimaryWinners'])
const lot = inject('lot')

const disabledButton = computed(() => {
  if (lot.divisibility && store.getters['offer/getApproveList'].length >= 1) return true
  else return false
})

const DEFAULT_ROWS = [
  {
    name: 'Итоговая цена c НДС',
    decorator: {
      name: 'Nds'
    },
    fieldNameTotalVAT: ['offer', 'totalVAT'],
    fieldNameTotalPrice: ['offer', 'totalPrice'],
    isGray: true
  },
  {
    name: 'Статус',
    isGray: true,
    decorator: {
      name: 'StatusButton',
      isDisabledButton: disabledButton,
      status: lot.status.id
    }
  },
  {
    isWhite: true,
    is_empty: true
  }
]
const DEFAULT_HEADROW = [
  {
    name: 'Место/ Участник',
    isNaming: true,
    width: '120px',
    value: 'name',
    isNoneComponent: true
  }
]
const headRow = reactive([])
const rows = reactive([])

const calcPercent = (num, percent) => {
  if (typeof num !== 'number' || typeof percent !== 'number') {
    throw new Error('Incorrect props in function')
  }
  return num * percent / 100
}

const calcTotalPrice = (offer) => {
  return offer.offers.reduce((accum, item) => {
    const count = +item.answers.position.quantity

    return +item.price * count + accum
  }, 0)
}

// чтобы выделить фиктивного добавь в offer is_fake: true,
const transformPositions = (offers) => {
  if (!offers.length) return

  return offers.map((offer, idx) => {
    const totalPrice = calcTotalPrice(offer)
    const ndsCounter = offer.lot.nds_counter
    return {
      subName: offer.user?.organization_name,
      idx,
      idsOffers: offer.offers.map(item => item.id),
      name: idx < 3 ? 'Место ' + (idx + 1) : null,
      nds_counter: ndsCounter,
      offer: {
        totalPrice,
        totalVAT: ndsCounter ? calcPercent(totalPrice, +ndsCounter) : 'нет данных'
      },
      ...offer
    }
  })
}

const getPositionIntoOffer = () => {
  if (Reflect.has(lot, 'id')) {
    const posList = lot.position.map((pos, idx) => ({
      ...pos,
      idx,
      decorator: {
        name: 'PositionChecked'
      }
    }))
    store.commit('offer/setPositions', posList)
  }
}

const getHeadRow = (list) => {
  headRow.length = 0
  headRow.push(...DEFAULT_HEADROW)
  if (list.length) {
    headRow.push(...transformPositions(list))
  }
}

const getRow = () => {
  rows.length = 0
  rows.push(...DEFAULT_ROWS)
  rows.push(...getActivePositions.value)
}

const transformOffers = (offers) => {
  getHeadRow(offers)
  rows.push(...DEFAULT_ROWS)
  getPositionIntoOffer()
}

watch(getActivePositions, () => {
  getRow()
}, { deep: true })

onMounted(() => {
  transformOffers(getOffers.value)
})

watch(getOffers, () => {
  transformOffers(getOffers.value)
})

watch(getWinnersList, () => {
  const list = [...getOffers.value]
  getWinnersList.value.forEach((primaryWinnerIdx, idx) => {
    if (primaryWinnerIdx) {
      arrayMove(list, primaryWinnerIdx, idx)
    }
  })
  getHeadRow(list)
  getRow()
})

const modifiedRows = [
  {
    decorator: {
      name: 'AllSee'
    },
    positionIdx: 3
  }
]

</script>

<template>
  <VTable
    :headRow="headRow"
    :rows="rows"
    :modified-rows="modifiedRows"
    :injectionModificationKey="TABLE_OFFER_INJECT"
    :is-checkbox="false"
    @open-modal="(data) => emit('open-modal', data)"
  />
</template>
