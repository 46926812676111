<script setup>
import VTextBlock from '@/components/textblock/VTextBlock'
import VFileList from '@/components/list/FileList/VFileList'
import { inject, reactive, watch, computed } from 'vue'
import VFile from '@/components/ui/form/File/VFile'
import { useStore } from 'vuex'
import { deleteFile } from '@/http/otherApi'
import { sendFilesOffer } from '@/http/offer/offerApi'

const lot = inject('lot')
const store = useStore()
const result = reactive({
  files: null
})

const getFiles = computed(() => {
  return store.getters['files/getFiles']
})

watch(result, async () => {
  if (result.files.length) {
    const data = new FormData()
    data.append('protocol', result.files[0])
    await sendFilesOffer(lot.id, data)
    store.commit('files/setFile', result.files[0])
    result.files.length = 0
  }
})

const organizerInfo = {
  place: 'tender.sitno.ru'
}

async function onDelete (val) {
  await deleteFile([val])
  store.commit('files/deleteFile', val)
}

</script>

<template>
  <div class="block__subtitle-small mb-30">
    Информация
  </div>
  <div class="grid grid--col-2 mb-60 p-30 bg--light-gray">
    <VTextBlock
      subtitle="Место проведения"
      :description="organizerInfo.place"
      is-link
    />
    <VTextBlock
      subtitle="Ответственный"
      :description="lot?.responsible_full_name"
      is-tel
    />
    <VTextBlock
      subtitle="Адрес электронной почты"
      :description="lot.user?.email"
      is-email
    />
  </div>

  <div class="block__subtitle-small mb-30">
    Документация
  </div>
  <VFileList
    v-if="getFiles"
    class="mb-20"
    grid
    :files="getFiles"
    is-download
    show-delete
    @onDelete="onDelete"
  />
  <VFile
    id="fileResult"
    name="files"
    class="mb-60"
    v-model="result.files"
  />
</template>
