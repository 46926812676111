import { reactive } from 'vue'
import useVModal from '@/use/Base/useVModal'
import { refreshArr } from '@/utils/reactive'

export default function ({ head }) {
  const tableHead = reactive([])
  const tableRows = reactive([])

  if (head.length) {
    refreshArr(tableHead, head)
  }

  const {
    showVModal: showModalTable,
    closeVModal,
    openVModal: openModalTable
  } = useVModal()

  function closeModalTable () {
    closeVModal()
    refreshModalTableRows([])
  }

  function refreshModalTableHead (newHead) {
    refreshArr(tableHead, newHead)
  }
  function refreshModalTableRows (newRows) {
    refreshArr(tableRows, newRows)
  }

  return {
    table: {
      head: tableHead,
      rows: tableRows
    },
    showModalTable,
    closeModalTable,
    openModalTable,
    refreshModalTableHead,
    refreshModalTableRows
  }
}
